.my-page {
    padding: 20px;
    text-align: center;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .card {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .card h2 {
    margin-bottom: 10px;
  }
  
  .card p {
    color: #777;
  }
  

  .back-button {
    background-color: #02a95c; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #388e3c; 
  }
  