.all-campaigns-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .all-campaigns-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 40px; 
  }
  
  .all-campaign-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .all-campaign-card .image-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
  }
  
  .all-campaign-card .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .all-campaign-card .campaign-info {
    padding: 15px;
  }
  
  .all-campaign-card h3 {
    margin: 0 0 10px 0;
    font-size: 1.2rem;
  }
  
  .all-campaign-card .campaign-progress {
    margin-top: 10px;
  }
  
  .all-campaign-card .progress-bar {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .all-campaign-card .progress {
    height: 100%;
    background-color: #4CAF50;
  }
  
  .all-campaign-card .amount-raised {
    margin-top: 5px;
    font-size: 0.9rem;
  }
  
  /* Pagination styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #e0e0e0;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination .current-page {
    margin: 0 10px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .all-campaigns-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .all-campaigns-grid {
      grid-template-columns: 1fr;
    }
  }



 



  .giveout-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Poppins", sans-serif;
  }
  
  .giveout-form-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .giveout-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input[type="text"],
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-group textarea {
    height: 100px;
    resize: vertical;
  }
  
 
  .file-upload-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .file-upload-area p {
    margin: 0;
    color: #666;
  }
  
  .file-upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    cursor: pointer;
  }
  
  .file-upload-button:hover {
    background-color: #e0e0e0;
  }
  
  .submit-button {
    background-color: #50d165;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
  }
  
  .submit-button:hover {
    background-color: #388e3c;
  }
  
/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}
.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.form-left label,
.form-right label {
    display: block;
    margin-bottom: 15px;
}
.form-left input,
.form-left textarea,
.form-right input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
}
.form-left textarea {
    height: 100px;
    resize: vertical;
}
.image-upload {
    display: block;
    cursor: pointer;
}
.image-upload-area {
    border: 2px dashed #ddd;
    border-radius: 10px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}
.upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #888;
}
.upload-placeholder span {
    margin-top: 10px;
}
.uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.cancel-button,
.save-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}
.cancel-button {
    background-color: #f0f0f0;
    color: #333;
}
.save-button {
    background-color: #05a081;
    color: white;
}
.cancel-button:hover {
    background-color: #e0e0e0;
}
.save-button:hover {
    background-color: #048c6f;
}



/* The  problem of css is here */
/* Admin Page Styles */
.admin-page {
    padding: 20px;
}

.admin-content {
    max-width: 1200px;
    margin: 0 auto;
}

.admin-content h1 {
    margin-bottom: 20px;
}

.error {
    color: red;
    margin-bottom: 20px;
}

.admin-campaign-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-campaign-table th,
.admin-campaign-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.campaign-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.admin-campaign-image {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}

.admin-edit-button {
    background-color: #05a081;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.admin-edit-button:hover {
    background-color: #048c6f;
}