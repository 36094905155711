.giveout-section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .giveout-content h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .giveout-layout {
    display: flex;
    gap: 30px;
  }
  
  .giveout-main {
    flex: 2;
  }
  
  .main-image {
    width: 100%;
    height: 400px; 
    object-fit: cover; 
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .giveout-sidebar {
    flex: 1;
  }
  
  .giveout-stats {
    background-color: #f8f8f8;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .contact-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .donor-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .donor-profile-picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .description {
    line-height: 1.6;
    margin-top: 15px; 
    padding-top: 10px; 
    border-top: 1px solid #ddd; 
  }
  


  .chatbox {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .chatbox-header {
    padding: 10px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chatbox-messages {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chatbox-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .chatbox-input input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 5px;
  }
  
  .chatbox-input button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }


  .all-giveout-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .all-giveout-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
  }
  
  
  .all-giveout-page h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  


/* Pagination styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #e0e0e0;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination .current-page {
    margin: 0 10px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .all-giveout-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .all-giveout-grid {
      grid-template-columns: 1fr;
    }
  }
  