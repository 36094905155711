@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
/* General Styles */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Hero Styles */
.nav-background {
  position: relative;
  background-image: url("./images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 40.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.25rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 2.25rem;
  width: 80%;
  max-width: 1200px;
  height: 3.125rem;
  margin: 0 auto; 

}

.navbar-left,
.navbar-right {
  display: flex;
  gap: 0.9375rem;
}

.logo {
  font-size: 1.5rem;
  color: #4caf50;
  margin: 0;
  font-weight: 800;
}

.nav-link,
.campaign-btn {
  height: 2rem;
  padding: 0 0.75rem;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  color: black;
}

.search-btn,
.leaderboard-btn,
.login-btn,
.logout-btn{
  gap: 0.5rem;
  border-radius: 0.625rem;
}

.search-btn:hover {
  padding-left: 0;
  padding-right: 25px;
}

.campaign-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  font-size: 0.9rem;
  border-radius: 1rem;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.nav-link:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.campaign-btn:hover {
  background-color: #388e3c;
}

.bottom-button-container {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
}

.bottom-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #50d165;
  cursor: pointer;
  font-size: 20px;
  /* font-weight: bold; */
  transition: color 0.3s ease;
}

.bottom-btn:hover {
  color: black;
}

.welcome-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  animation: fadeInOut 3s forwards;
  z-index: 1000; 
}

@keyframes fadeInOut {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  10% {
      opacity: 1;
      transform: translateY(0);
  }
  90% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(20px);
  }
}
.infield {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}





/* Auth Page Styles */
.auth-container {
  position: relative;
  width: 850px;
  height: 500px;
  background-color: #fff;
  box-shadow: 25px 30px 55px #5557;
  border-radius: 13px;
  overflow: hidden;
  margin: 50px auto;
}

.auth-form-container {
  position: absolute;
  width: 60%;
  height: 100%;
  padding: 0px 40px;
  transition: all 0.6s ease-in-out;
}

.sign-up-container {
  opacity: 0;
  z-index: 1;
}

.sign-in-container {
  z-index: 2;
}

.auth-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.auth-form h1 {
  color: #4caf50;
}

.social-container {
  margin: 20px 0px;
}

.social-container a {
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  height: 40px;
  width: 40px;
}

.auth-form span {
  font-size: 12px;
}

.infield {
  position: relative;
  margin: 8px 0px;
  width: 100%;
}

.auth-form input {
  width: 100%;
  padding: 15px;
  background-color: #f3f3f3;
  border: none;
  outline: none;
}

.infield label {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%);
  width: 0%;
  height: 2px;
  background: linear-gradient(to right, #4caf50, #388e3c);
  transition: 0.3s;
}

.auth-form input:focus ~ label {
  width: 100%;
}

.auth-form a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0px;
}

.auth-form a.forgot {
  padding-bottom: 3px;
  border-bottom: 2px solid #eee;
}

.auth-form button {
  border-radius: 20px;
  border: 1px solid #4caf50;
  background: #388e3c;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 17px;
  transition: 80ms ease-in;
}

.auth-form button:hover {
  background: #fff;
  color: #4caf50;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 60%;
  width: 40%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 9;
}

#overlayBtn {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 304px;
  transform: translateX(-50%);
  width: 143.67px;
  height: 40px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 20px;
}

.overlay {
  position: relative;
  background: linear-gradient(to right, #4caf50, #388e3c);
  color: #fff;
  left: -150%;
  height: 100%;
  width: 250%;
  transition: transform 0.6s ease-in-out;
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  text-align: center;
  height: 100%;
  width: 340px;
  transition: 0.6s ease-in-out;
}

.overlay-left {
  right: 60%;
  transform: translateX(-12%);
}

.overlay-right {
  right: 0;
  transform: translateX(0%);
}

.overlay-panel h1 {
  color: #fff;
}

.overlay-panel p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 25px 0px 35px;
}

.overlay-panel button {
  border: none;
  background-color: transparent;
  padding-top: 25px;
}

.right-panel-active .overlay-container {
  transform: translateX(-150%);
}

.right-panel-active .overlay {
  transform: translateX(50%);
}

.right-panel-active .overlay-left {
  transform: translateX(25%);
}

.right-panel-active .overlay-right {
  transform: translateX(35%);
}

.right-panel-active .sign-in-container {
  transform: translateX(20%);
  opacity: 0;
}

.right-panel-active .sign-up-container {
  transform: translateX(66.7%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  50% {
    opacity: 0;
    z-index: 1;
  }
  50.1%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.btnScaled {
  animation: scaleBtn 0.6s;
}

@keyframes scaleBtn {
  0% {
    width: 143.67px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 143.67px;
  }
}

.main-content {
  width: 100%;
  padding: 60px 20px;
  background-color: #f0f7f0; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 90vh;
}

.text-content {
  max-width: 50%;
}

.text-content h1 {
  font-size: 2.25rem; /* 36px */
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #333333;
}

.text-content p {
  font-size: 1.1rem; /* 16px */
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333333;
  max-width: 800px;
}

.image-content {
  max-width: 50%; 
  display: flex;
  justify-content: center;
  padding-right: 20px;
}

.image-content img {
  max-width: 100%;
  height: auto;
  display: block;
  max-height: 400px; 
}


.donate-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  flex-direction: row-reverse; 
}

.donate-text-content {
  flex: 1;
  padding-left: 20px;
}

.donate-text-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.donate-text-content p {
  font-size: 18px;
  margin-bottom: 20px;
}

.give-out-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.give-out-button:hover {
  background-color: #218838;
}

.donate-image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.donate-image {
  max-width: 90%; 
  max-height: 400px;
  width: 90%; 
  object-fit: cover; 
  border-radius: 10px;
}

.footer {
  background-color: #ffffff; 
  color: #333333; 
  padding: 40px 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  flex: 1;
  text-align: left; 
}

.footer-logo h1 {
  margin: 0;
  font-size: 2rem;
  color: #4caf50; 
}

.footer-nav {
  flex: 2;
  text-align: center; 
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; 
  gap: 20px;
}

.footer-nav ul li {
  display: inline;
}

.footer-nav ul li a {
  color: #333333; 
  text-decoration: none;
  font-size: 1rem;
}

.footer-nav ul li a:hover {
  color: #4caf50;
}

.footer-social {
  flex: 1;
  text-align: right; 
}

.footer-social {
  display: flex;
  justify-content: flex-end; 
  gap: 20px;
}

.footer-social .social-icon {
  color: #333333; 
  font-size: 1.5rem;
  text-decoration: none;
}

.footer-social .social-icon:hover {
  color: #4caf50; 
}

.footer-bottom {
  border-top: 1px solid #dddddd; 
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
}



.popular-campaigns {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.campaigns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.popular-campaigns h2 {
  font-size: 2rem;
  margin: 0;
}

.navigation-buttons button {
  background: #f0f0f0;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.navigation-buttons button:hover {
  background: #e0e0e0;
}
.navigation-buttons button:disabled {
  background-color: #d6d6d6; /* Disabled button color */
  color: #a0a0a0;
  cursor: not-allowed;
}

.navigation-buttons button:disabled:hover {
  background-color: #d6d6d6; /* Maintain disabled color on hover */
}

.campaign-grid {
  display: grid;
  grid-template-areas: 
    "main main side1 side2"
    "main main side3 side4";
  grid-template-rows: auto;
  grid-template-columns: 0.7fr 0.7fr 1fr 1fr;
  gap: 1rem;
}

.campaign-grid > .main { grid-area: main; }
.campaign-grid > .side1 { grid-area: side1; }
.campaign-grid > .side2 { grid-area: side2; }
.campaign-grid > .side3 { grid-area: side3; }
.campaign-grid > .side4 { grid-area: side4; }

.campaign-card {
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.image-container {
  position: relative;
}

.campaign-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.campaign-grid > .main img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.donor-count {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #D9D9D9;
  color: black;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.8rem;
}

.campaign-info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.campaign-card h3 {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  line-height: 1.2;
  height: 2.4em; 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.campaign-progress {
  margin-top: auto;
}

.progress-bar {
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress {
  height: 100%;
  background-color: #4caf50;
}

.amount-raised {
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0;
}

.explore-container {
  text-align: center; 
}

.explore-all {
  display: inline-block; 
  margin: 2rem auto 0;
  padding: 0.75rem 1.5rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none; 
  color: inherit; 
}

.explore-all:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .campaign-grid {
    grid-template-areas: 
      "main"
      "side1"
      "side2"
      "side3"
      "side4";
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .campaign-card img {
    height: 150px; 
  }
}


/* GiveOutCard styles */
.giveout-card-link {
  text-decoration: none;
  color: inherit;
}

.giveout-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.giveout-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.giveout-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.giveout-name {
  margin: 0;
  font-weight: bold;
}
/* GiveOut styles */
.giveout-section {
  text-align: center;
  margin: 20px;
}

.giveout-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.giveout-cards {
  display: flex;
  gap: 20px;
}

.giveout-wrapper button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
}

.giveout-wrapper button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.more-items {
  margin-top: 20px;
}

.more-items a {
  text-decoration: none;
  color: #4caf50;
  font-weight: bold;
}

.more-items a:hover {
  color: #388e3c;
}

/* GiveOutCard styles */
.giveout-card-link {
  text-decoration: none;
  color: inherit;
}

.giveout-card {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.giveout-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.giveout-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.giveout-content {
  padding: 15px;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.giveout-name {
  margin: 0;
  font-weight: bold;
}

.giveout-item {
  margin: 0 0 5px;
  font-size: 18px;
}

.giveout-condition {
  margin: 0;
  color: #666;
}


/* NavigationBar style */
.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 2.25rem;
  width: 80%;
  max-width: 1200px;
  height: 3.125rem;
  margin: 0 auto;
  margin-top: 20px;
}

.custom-navbar-left,
.custom-navbar-right {
  display: flex;
  gap: 0.9375rem;
}

.custom-logo {
  font-size: 1.5rem;
  color: #4caf50;
  margin: 0;
  font-weight: 800;
}

.custom-nav-link,
.custom-campaign-btn {
  height: 2rem;
  padding: 0 0.75rem;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  color: black;
}

.custom-search-btn,
.custom-leaderboard-btn,
.custom-login-btn,
.custom-logout-btn{
  gap: 0.5rem;
  border-radius: 0.625rem;
}
.custom-search-btn:hover{
  padding-left: 0;
  padding-right: 25px;
}

.custom-nav-link:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.custom-icon {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.custom-campaign-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  font-size: 0.9rem;
  border-radius: 1rem;
}

.custom-campaign-btn:hover {
  background-color: #388e3c;
}

.custom-login-btn,
.custom-logout-btn {
  gap: 0.5rem;
  border-radius: 0.625rem;
}

.custom-login-btn:hover,
.custom-logout-btn:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

/* CardSection style */

.card-section-container .card-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card-section-container .campaign-header {
  display: flex;
  justify-content: space-between;
}

.card-section-container .campaign-header h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.card-section-container .campaign-main {
  display: flex;
  gap: 30px;
}

.card-section-container .campaign-content {
  flex: 2;
}

.card-section-container .campaign-sidebar {
  flex: 1;
}

.card-section-container .campaign-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.card-section-container .organizer-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.card-section-container .organizer-info p {
  margin: 0;
}

.card-section-container hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 20px 0;
}

.card-section-container .campaign-statement {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.card-section-container .campaign-progress {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.card-section-container .amount-raised {
  font-size: 19px;
  margin-bottom: 10px;
}

.card-section-container .amount-raised .raised {
  font-weight: bold;
}

.card-section-container .amount-raised {
  font-weight: normal;
  font-size: 19px;
}

.card-section-container .progress-bar {
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.card-section-container .progress {
  height: 100%;
  background-color: #02a95c;
}

.card-section-container .donation-count {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.card-section-container .action-buttons {
  display: flex;
  gap: 10px;
}

.card-section-container .donate-btn,
.card-section-container .share-btn {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-section-container .donate-btn {
  background-color: #02a95c;
  color: white;
}

.card-section-container .donate-btn:hover {
  background-color: #018a4a;
}

.card-section-container .share-btn {
  background-color: #f5f5f5;
  color: #333;
}

.card-section-container .share-btn:hover {
  background-color: #e5e5e5;
}

.card-section-container .organizer-section {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

.card-section-container .organizer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-section-container .organizer-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.card-section-container .organizer-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.card-section-container .organizer-section h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.card-section-container .organizer-section p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.card-section-container .contact-btn {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-section-container .contact-btn:hover {
  background-color: #e5e5e5;
}

.card-section-container .comment-divider {
  margin: 40px 0;
  border: none;
  border-top: 1px solid #e0e0e0;
}

.card-section-container .comment-section {
  margin-top: 40px;
}

.card-section-container .comment-section h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-section-container .comment-section form {
  display: flex;
  flex-direction: column;
}

.card-section-container .comment-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  resize: vertical;
}

.card-section-container .comment-section button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #02a95c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-section-container .comment-section button:hover {
  background-color: #018a4a;
}

.card-section-container .existing-comments .comment {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.card-section-container .existing-comments .comment p {
  margin: 0;
}




/* Modal Background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.modal-close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.modal-close-btn:hover {
  color: #666;
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* Input Fields */
.modal-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

.modal-input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* File Input */
.modal-input[type="file"] {
  padding: 0.75rem;
  cursor: pointer;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-submit-btn {
  background-color: #4caf50;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-submit-btn:hover {
  background-color: #388e3c;
}

.modal-cancel-btn {
  background-color: #f44336;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-cancel-btn:hover {
  background-color: #d32f2f;
}


:root {
  --color-dark: #2C3131;
  --color-light: #F3F3F2;
  --color-primary: #000;
  --color-accent: #4caf50; 
}



#main {
  display: flex;
  flex-direction: column; 
  align-items: center;
  height: 100%;
  background-color: var(--color-light);
  padding: 1em; 
}

.board {
  width: 100%; 
  max-width: 1200px; 
  padding: 1em;
  background-color: transparent; 
}

.leaderboard {
  font-size: 2rem;
  color: var(--color-accent); 
  margin-bottom: 1em;
  text-align: center; 
}

.duration {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
}

.duration > button {
  border: 1px solid var(--color-accent); 
  padding: 0.5em 2em;
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  color: var(--color-accent);
  transition: background-color 0.3s, color 0.3s;
}

.duration > button:hover,
.duration > button.active {
  background-color: var(--color-accent); 
  color: var(--color-light);
}

#profile {
  margin-top: 2em;
}

#profile .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-bottom: 1.5em;
  padding: 0.5em 0;
  border-bottom: 1px solid #eaeaea;
}
#profile .flex .rank {
  font-size: 1.5rem; 
  color: var(--color-accent);
  background-color: var(--color-light);
  border: 2px solid var(--color-accent);
  border-radius: 50%;
  width: 1.5em; 
  height: 1.5em; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 1em;
}


#profile .flex .item {
  display: flex;
  align-items: center;
}

#profile .flex img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1em;
}

#profile .flex .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#profile .flex .info .name {
  font-size: 1.2rem;
  color: var(--color-primary);
  margin: 0;
}

#profile .flex .donation-amount {
  font-size: 1.2rem;
  color: var(--color-primary);
}



.back-button {
  background-color: #02a95c; 
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #388e3c; 
}



