.campaignpage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}
  
.campaignpage-step-indicator {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}
  
.campaignpage-h1 {
    font-size: 32px;
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 10px;
}
  
.campaignpage-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}
  
.campaignpage-form-group {
    margin-bottom: 20px;
}
  
.campaignpage-form-label {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.campaignpage-form-label:hover {
    background-color: #f8f8f8;
}
  
.campaignpage-form-radio {
    margin-right: 15px;
}
  
.campaignpage-form-text {
    flex-grow: 1;
}
  
.campaignpage-form-title {
    font-size: 18px;
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 5px;
}
  
.campaignpage-form-description {
    font-size: 14px;
    color: #666;
}
  
.campaignpage-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}
  
.campaignpage-back-button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: white;
    color: #333;
    border-radius: 4px;
    cursor: pointer;
}
  
.campaignpage-continue-button {
    padding: 10px 20px;
    background-color: #4caf50; 
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
.campaignpage-continue-button:hover {
    background-color: #43a047; 
}

.campaignpage-step2-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}
  
.campaignpage-step2-indicator {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}
  
.campaignpage-step2-h1 {
    font-size: 32px;
    font-weight: bold;
    color: #4caf50; 
    margin-bottom: 10px;
}
  
.campaignpage-step2-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}
  
.campaignpage-step2-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
  
.campaignpage-step2-currency-symbol {
    font-size: 18px;
    margin-right: 10px;
}
  
.campaignpage-step2-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.campaignpage-step2-currency-code {
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
}
  
.campaignpage-step2-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}
  
.campaignpage-step2-requirements {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 4px;
}
  
.campaignpage-step2-requirements h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #4caf50;
}
  
.campaignpage-step2-requirements ul {
    list-style-type: disc;
    padding-left: 20px;
}
  
.campaignpage-step2-requirements li {
    font-size: 14px;
    margin-bottom: 5px;
}
  
.campaignpage-step2-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
  
.campaignpage-step2-back-button,
.campaignpage-step2-continue-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
  
.campaignpage-step2-back-button {
    background-color: white;
    border: 1px solid #ccc;
    color: #333;
}
  
.campaignpage-step2-continue-button {
    background-color: #4caf50; 
    color: white;
    border: none;
}
  
.campaignpage-step2-continue-button:hover {
    background-color: #43a047; 
}

.step3-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.step3-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #4caf50; 
}

.step3-subheader {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.step3-upload-container {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 40px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    overflow: hidden;
}

.step3-upload-icon {
    font-size: 48px;
    color: #888;
    margin-bottom: 10px;
}

.step3-upload-text {
    font-size: 16px;
    color: #4caf50; 
}

.step3-preview-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.step3-country-container {
    margin-bottom: 20px;
}

.step3-country-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.step3-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.step3-back-button,
.step3-continue-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.step3-back-button {
    background-color: white;
    border: 1px solid #ccc;
    color: #333;
}

.step3-continue-button {
    background-color: #4caf50; 
    color: white;
    border: none;
}

.step3-continue-button:hover {
    background-color: #43a047; 
}

.step4-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.step4-header {
    font-size: 24px;
    font-weight: bold;
    color: #4caf50; 
    margin-bottom: 10px;
}

.step4-subheader {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.step4-list {
    margin-bottom: 20px;
    color: #666;
}

.step4-list li {
    margin-bottom: 10px;
}

.step4-form-group {
    margin-bottom: 20px;
}

.step4-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #4caf50; 
}

.step4-input,
.step4-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.step4-char-count {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

.step4-error-message {
    font-size: 14px;
    color: red;
    margin-top: 5px;
}

.step4-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.step4-back-button,
.step4-review-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.step4-back-button {
    background-color: white;
    border: 1px solid #ccc;
    color: #333;
}

.step4-review-button {
    background-color: #4caf50; 
    color: white;
    border: none;
}

.step4-review-button:hover {
    background-color: #43a047; 
}

.step5-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.step5-header {
    font-size: 24px;
    font-weight: bold;
    color: #4caf50; 
    margin-bottom: 10px;
}

.step5-subheader {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.step5-review-section {
    margin-bottom: 20px;
}

.step5-section-title {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
}

.step5-section-content {
    font-size: 16px;
    color: #666;
}

.step5-cover-image {
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
    border: 2px solid #4caf50; 
}

.step5-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.step5-preview-button,
.step5-launch-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.step5-preview-button {
    background-color: white;
    border: 1px solid #ccc;
    color: #333;
}

.step5-launch-button {
    background-color: #4caf50; 
    color: white;
    border: none;
}

.step5-launch-button:hover {
    background-color: #43a047; 
}
.step5-preview-button:active,
.step5-launch-button:active {
  transform: translateY(1px);
}
.step5-cover-image {
  max-width: 200px; 
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px; 
  margin-top: 10px; 
}
